import { useEffect, useRef, useState } from 'react';

import loadable from '@loadable/component';

import BpkSelectableChip from '@skyscanner/backpack-web/bpk-component-chip';
import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';
import DesktopScrollContainer from '../common/DesktopScrollContainer/DesktopScrollContainer';
import FooterBlurb from '../common/FooterBlurb';
import MBD from '../common/MBD/MBD';

import DestinationContainer from './DestinationContainer';

import type {
  PopularDestination,
  PopularDestinationsProps,
} from 'common-types/types/PopularDestinationsProps';

import STYLES from './PopularDestinations.module.scss';

const MOBILE_CARD_LIST_LIMIT = 3;
const UNKNOWN_CAR_CLASS = 'UNKNOWN_CAR_CLASS';
const UNSET_CAR_CATEGORY = 'UNSET_CAR_CATEGORY';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const logNextEvent = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.POPULAR_DESTINATIONS,
    component_action:
      COMPONENT_ACTION.POPULAR_DESTINATIONS.POPULAR_DESTINATIONS_NEXT_CLICKED,
  });
};

const logPrevEvent = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.POPULAR_DESTINATIONS,
    component_action:
      COMPONENT_ACTION.POPULAR_DESTINATIONS.POPULAR_DESTINATIONS_PREV_CLICKED,
  });
};

type SelectedChipsProps = {
  activeChipIndex: number;
  setActiveChipIndex: (index: number) => void;
  chips: string[];
  resetPagination: () => void;
};

const SelectedChips = ({
  activeChipIndex,
  chips,
  resetPagination,
  setActiveChipIndex,
}: SelectedChipsProps) => {
  if (!chips || !chips.length) {
    return null;
  }

  return (
    <div className={STYLES.SelectableChip}>
      <BpkMobileScrollContainer>
        {chips.map(
          (chip, index) =>
            chip && (
              <div key={chip} className={STYLES.SelectableChip__item}>
                <BpkSelectableChip
                  key={chip}
                  accessibilityLabel={chip}
                  selected={index === activeChipIndex}
                  onClick={() => {
                    setActiveChipIndex(index);
                    resetPagination();
                  }}
                >
                  {chip}
                </BpkSelectableChip>
              </div>
            ),
        )}
      </BpkMobileScrollContainer>
    </div>
  );
};

const PopularDestinations = (props: PopularDestinationsProps) => {
  const {
    disclaimer,
    grouping = false,
    mobileScroll,
    noShowImage,
    popularDestinations,
    relFollow,
    showAll,
    stringSources,
    subtitle,
    title,
  } = props;
  const { from, perDay } = stringSources;

  // visibleIndexs: The currently visible Card indexs
  // showedIndexs: The indexs of the Card that has displayed and lauched the mini-event
  const [visibleIndexs, setVisibleIndexs] = useState<number[]>([]);
  const [showedIndexs, setShowIndexs] = useState<number[]>([]);
  const [activeChipIndex, setActiveChipIndex] = useState(0);
  const paginationRef = useRef({ resetPagination: () => {} });

  const componentName =
    props.componentName || COMPONENT_NAME.POPULAR_DESTINATIONS;
  const mbdName =
    props.componentName === 'POPULAR_AIRPORTS'
      ? 'seo_popular_airports'
      : 'seo_popular_destinations';

  const chips = grouping
    ? Array.from(
        new Set(
          popularDestinations
            .map((item) => item.groupKey)
            .filter((key): key is string => !!key),
        ),
      )
    : [];

  const renderDestinations = grouping
    ? popularDestinations.filter(
        (destination) => chips[activeChipIndex] === destination.groupKey,
      )
    : popularDestinations;

  const logVisibleEvent = (indexs: number[] | number) => {
    let viewIndexs = [];
    if (indexs instanceof Array) {
      viewIndexs = indexs.filter((i) => !showedIndexs.includes(i));
    } else {
      const indexsArray = Array.from(Array(indexs), (_, i) => i);
      viewIndexs = indexsArray.filter((i) => !showedIndexs.includes(i));
    }
    if (viewIndexs.length) {
      setShowIndexs([...showedIndexs, ...viewIndexs]);
      const payload = {
        action_type: ACTION_TYPE.CONTENT_VISIBLE,
        component_name: componentName,
        destinations: viewIndexs.map((index) => ({
          destinationName: renderDestinations[index]?.name,
          url: renderDestinations[index]?.redirectUrl,
          price: {
            currency: renderDestinations[index]?.cheapestCarPrice?.currencyCode,
            amount: Number(renderDestinations[index]?.cheapestCarPrice?.amount),
            unit:
              renderDestinations[index]?.cheapestCarPrice?.unit
                ?.toString()
                .replace('UNIT_', '') || 'CENTI',
          },
          carType:
            renderDestinations[index]?.popularCarType === UNKNOWN_CAR_CLASS
              ? UNSET_CAR_CATEGORY
              : renderDestinations[index]?.popularCarType,
          index,
        })),
      };
      // Logging supplier id for recommendation widgets analysis
      const SUPPLIER_ID =
        renderDestinations[0]?.redirectUrl.match(/supplier:(\d+)/g);
      SUPPLIER_ID?.length
        ? logMiniEventHelper({
            ...payload,
            supplier: { id: Number(SUPPLIER_ID[0].split(':')[1]) },
          })
        : logMiniEventHelper(payload);
    }
  };

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: componentName,
      load_status: LOAD_STATUS.LOADED,
    });
  }, [componentName]);

  if (!title || !renderDestinations?.length) {
    return null;
  }

  return (
    <MBD id={mbdName} componentName={mbdName}>
      <div className={STYLES.PopularDestinations}>
        <div className={STYLES.PopularDestinations__header}>
          <BpkSectionHeader title={title} description={subtitle} />
        </div>
        <SelectedChips
          chips={chips}
          activeChipIndex={activeChipIndex}
          setActiveChipIndex={setActiveChipIndex}
          resetPagination={paginationRef.current?.resetPagination ?? (() => {})}
        />
        {showAll ? (
          <div className={STYLES.PopularDestinations__desktop}>
            <IntersectionObserverWrapper
              onElementSeen={() => logVisibleEvent(renderDestinations?.length)}
            >
              <div className={STYLES.PopularDestinations__cardlist}>
                {renderDestinations.map(
                  (destination: PopularDestination, index: number) => (
                    <div
                      key={`${destination.subtitle}_${index + 1}`}
                      className={
                        STYLES.PopularDestinations__cardlist__container
                      }
                    >
                      <DestinationContainer
                        destination={destination}
                        from={from}
                        perDay={perDay}
                        index={index}
                        componentName={componentName}
                        relFollow={relFollow}
                        noShowImage={noShowImage}
                      />
                    </div>
                  ),
                )}
              </div>
            </IntersectionObserverWrapper>
          </div>
        ) : (
          <DesktopScrollContainer
            nextLabel="next"
            prevLabel="prev"
            logPrevEvent={logPrevEvent}
            logNextEvent={logNextEvent}
            setVisibleIndexs={setVisibleIndexs}
            ref={paginationRef}
          >
            {renderDestinations.map(
              (destination: PopularDestination, index: number) => (
                <div
                  key={`${destination.subtitle}_${index + 1}`}
                  className={STYLES.PopularDestinations__scroll__container}
                >
                  <IntersectionObserverWrapper
                    ioWrapperClassName={STYLES.PopularDestinations__ioWrapper}
                    onElementSeen={() => logVisibleEvent(visibleIndexs)}
                  >
                    <DestinationContainer
                      destination={destination}
                      from={from}
                      perDay={perDay}
                      index={index}
                      componentName={componentName}
                      relFollow={relFollow}
                      noShowImage={noShowImage}
                    />
                  </IntersectionObserverWrapper>
                </div>
              ),
            )}
          </DesktopScrollContainer>
        )}
        {mobileScroll ? (
          <BpkMobileScrollContainer
            className={STYLES.PopularDestinations__mobile}
          >
            <div className={STYLES.PopularDestinations__scroll__list}>
              {renderDestinations &&
                renderDestinations.map(
                  (destination: PopularDestination, index: number) => (
                    <div
                      key={`${destination.subtitle}_${index + 1}`}
                      className={STYLES.PopularDestinations__scroll__container}
                    >
                      <IntersectionObserverWrapper
                        ioWrapperClassName={
                          STYLES.PopularDestinations__ioWrapper
                        }
                        onElementSeen={() => logVisibleEvent([index])}
                      >
                        <DestinationContainer
                          destination={destination}
                          from={from}
                          perDay={perDay}
                          index={index}
                          componentName={componentName}
                          relFollow={relFollow}
                          mobileScroll={mobileScroll}
                          noShowImage={noShowImage}
                        />
                      </IntersectionObserverWrapper>
                    </div>
                  ),
                )}
            </div>
          </BpkMobileScrollContainer>
        ) : (
          <IntersectionObserverWrapper
            onElementSeen={() => logVisibleEvent(MOBILE_CARD_LIST_LIMIT)}
          >
            <div className={STYLES.PopularDestinations__mobile}>
              <div className={STYLES.PopularDestinations__mobileCardList}>
                {renderDestinations &&
                  renderDestinations
                    .slice(0, MOBILE_CARD_LIST_LIMIT)
                    .map((destination: PopularDestination, index: number) => (
                      <div
                        key={`${destination.subtitle}_${index + 1}`}
                        className={
                          STYLES.PopularDestinations__mobileCardList__container
                        }
                      >
                        <DestinationContainer
                          destination={destination}
                          from={from}
                          perDay={perDay}
                          index={index}
                          componentName={componentName}
                          relFollow={relFollow}
                          mobileScroll={mobileScroll}
                          noShowImage={noShowImage}
                        />
                      </div>
                    ))}
              </div>
            </div>
          </IntersectionObserverWrapper>
        )}
        {disclaimer && (
          <div className={STYLES.PopularDestinations__footer}>
            <FooterBlurb disclaimerCopy={disclaimer} />
          </div>
        )}
      </div>
    </MBD>
  );
};

export default (props: PopularDestinationsProps) => (
  <IntersectionObserverWrapper
    threshold={0.5}
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name:
          props.componentName || COMPONENT_NAME.POPULAR_DESTINATIONS,
      })
    }
  >
    <PopularDestinations {...props} />
  </IntersectionObserverWrapper>
);
